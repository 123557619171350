var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-spacer'),(
              _vm.trans_type == 'e' &&
              _vm.editedItem.currency_id == 114 &&
              _vm.editedItem.sens
            )?_c('h2',[_vm._v(" "+_vm._s(_vm.editedItem.sens == "1" ? "استلام" : "تسليم")+" ")]):_vm._e(),(_vm.trans_type == 'i' && _vm.editedItem.sens)?_c('h2',[_vm._v(" "+_vm._s(_vm.editedItem.sens == -1 ? "استلام" : "تسليم")+" ")]):_vm._e(),(_vm.trans_type == 'e' && _vm.editedItem.currency_id != 114)?_c('h2',[_vm._v(" "+_vm._s(_vm.editedItem.sens == 1 ? "Achat" : "Vente")+" ")]):_vm._e(),_c('v-spacer'),_c('v-chip',{attrs:{"color":_vm.editedItem.status_color}},[_vm._v(" "+_vm._s(_vm.editedItem.status_name)+" ")])],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.editedItem.tier_name))]),_c('v-card-subtitle',[(
              _vm.trans_type == 'e' &&
              _vm.editedItem.currency_id == 114 &&
              _vm.editedItem.id > 0
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" "+_vm._s("Opr. Org. : " + _vm.editedItem.org_no_opr)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" "+_vm._s("Montant Initial : " + _vm.numberWithSpace( _vm.editedItem.mont_init ? parseFloat(_vm.editedItem.mont_init).toFixed(2) : 0.0 ))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" "+_vm._s((_vm.editedItem.sens == 1 ? "Reçu : " : "Transfere : ") + _vm.numberWithSpace( _vm.editedItem.ttc_paye ? parseFloat(_vm.editedItem.ttc_paye).toFixed(2) : 0.0 ))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" "+_vm._s("Reste : " + _vm.numberWithSpace( _vm.editedItem.reste ? parseFloat(_vm.editedItem.reste).toFixed(2) : 0.0 ))+" ")])],1):_vm._e()],1),_c('v-card-text',[_c('v-container',[_c('v-tabs',{staticClass:"my-tabs",on:{"change":_vm.tab_change},model:{value:(_vm.tab1),callback:function ($$v) {_vm.tab1=$$v},expression:"tab1"}},[_c('v-tab',{key:"4",attrs:{"href":"#4"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-cash-outline ")]),_vm._v(" Transaction ")],1),(_vm.editedItem.id != -1)?_c('v-tab',{key:"5",attrs:{"href":"#5"}},[_vm._v(" Frais ")]):_vm._e(),_c('v-spacer'),(_vm.editedItem.id != -1)?_c('v-tab',{key:"8",attrs:{"href":"#8"}},[_vm._v(" Documents attachés "),_c('v-badge',{attrs:{"bordered":"","color":_vm.editedItem.nbdocs == 0 ? 'red' : 'blue',"overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.editedItem.nbdocs)+" ")])]},proxy:true}],null,false,4024270495)},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-paperclip ")])],1)],1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{attrs:{"value":'4'}},[_c('v-container',[_c('v-row',[(
                          !_vm.tier && _vm.editedItem.id == -1 && _vm.trans_type == 'e'
                        )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiers,"item-text":'nom',"item-value":'id',"label":"Tier","rules":[function (v) { return !!v || 'Tier obligatoire'; }],"outlined":"","dense":"","readonly":!_vm.modify ||
                            _vm.editedItem.trans_id > 0 ||
                            _vm.editedItem.credit_id > 0 ||
                            _vm.editedItem.echeance_id > 0},on:{"change":_vm.tier_change},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.nom)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.tier_type_id == 1 ? "Client" : item.tier_type_id == 2 ? "Fournisseur" : "Autre")+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s("Solde " + (item.solde ? _vm.numberWithSpace( parseFloat(item.solde).toFixed(2) ) : 0.0))+" ")])],1)]}}],null,false,2513920223),model:{value:(_vm.editedItem.tier_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "tier_id", $$v)},expression:"editedItem.tier_id"}})],1):_vm._e(),(_vm.trans_type == 'e' && _vm.editedItem.create_uid != 1)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":[
                            { id: 1, label: 'Achat' },
                            { id: -1, label: 'Vente' } ],"item-text":"label","item-value":"id","label":"Operation","rules":[function (v) { return !!v || 'Operation obligatoire'; }],"dense":"","outlined":"","readonly":!_vm.modify ||
                            _vm.editedItem.trans_id > 0 ||
                            _vm.editedItem.credit_id > 0 ||
                            _vm.editedItem.echeance_id > 0},scopedSlots:_vm._u([{key:"selection",fn:function(ref){return [_c('v-chip',{attrs:{"color":_vm.editedItem.sens == 1 ? 'green' : 'orange',"small":""}},[_c('span',[_vm._v(_vm._s(_vm.editedItem.sens == 1 ? "Achat" : "Vente"))])])]}},{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.id == 1 ? 'green' : 'orange',"small":""}},[_c('span',[_vm._v(_vm._s(item.id == 1 ? "Achat" : "Vente"))])])]}}],null,false,1347834825),model:{value:(_vm.editedItem.sens),callback:function ($$v) {_vm.$set(_vm.editedItem, "sens", _vm._n($$v))},expression:"editedItem.sens"}})],1):_vm._e(),(_vm.trans_type == 'i')?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":[
                            { id: -1, label: 'Décaissser' },
                            { id: 1, label: 'Alimenter' } ],"item-text":"label","item-value":"id","label":"Operation","rules":[function (v) { return !!v || 'Operation obligatoire'; }],"dense":"","outlined":"","readonly":!_vm.modify ||
                            _vm.editedItem.trans_id > 0 ||
                            _vm.editedItem.credit_id > 0 ||
                            _vm.editedItem.echeance_id > 0},scopedSlots:_vm._u([{key:"selection",fn:function(ref){return [_c('v-chip',{attrs:{"color":_vm.editedItem.sens == 1 ? 'green' : 'orange',"small":""}},[_c('span',[_vm._v(_vm._s(_vm.editedItem.sens == -1 ? "Décaissser" : "Alimenter"))])])]}},{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.id == 1 ? 'green' : 'orange',"small":""}},[_c('span',[_vm._v(_vm._s(item.id == -1 ? "Décaissser" : "Alimenter"))])])]}}],null,false,3735837545),model:{value:(_vm.editedItem.sens),callback:function ($$v) {_vm.$set(_vm.editedItem, "sens", _vm._n($$v))},expression:"editedItem.sens"}})],1):_vm._e(),(
                          (_vm.$store.state.auth.includes('02003') ||
                            _vm.$store.state.isadmin) &&
                          _vm.trans_type == 'i'
                        )?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":_vm.users_list,"item-text":"name","item-value":"id","label":"Caisse de ","rules":[function (v) { return !!v || 'Caisse obligatoire'; }],"dense":"","outlined":"","readonly":!_vm.modify},on:{"change":_vm.caisse_change},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s("Solde " + (item.solde ? _vm.numberWithSpace( parseFloat(item.solde).toFixed(2) ) : 0.0))+" ")])],1)]}}],null,false,571577796),model:{value:(_vm.editedItem.user_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "user_id", $$v)},expression:"editedItem.user_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('currencyinput',{key:_vm.mk,attrs:{"label":"Montant","rules":[
                            function (v) { return !!v || v > 0 || 'Montant obligatoire'; } ],"readonly":!_vm.modify &&
                            !(
                              _vm.editedItem.statut_id == 1 &&
                              _vm.editedItem.active == 1 &&
                              (_vm.$store.state.auth.includes('03003') ||
                                _vm.$store.state.isadmin)
                            )},on:{"input":_vm.mont_change,"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.montant),callback:function ($$v) {_vm.$set(_vm.editedItem, "montant", $$v)},expression:"editedItem.montant"}})],1),(_vm.editedItem.create_uid != 1)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.currencies,"item-text":'name',"item-value":'id',"label":"Monnaie","filter":_vm.customFilter,"rules":[function (v) { return !!v || 'Monnaie obligatoire'; }],"outlined":"","dense":"","readonly":!_vm.modify ||
                            !(
                              _vm.$store.state.auth.includes('02003') ||
                              _vm.$store.state.isadmin
                            ) ||
                            _vm.caisse.owner == 0},on:{"change":_vm.cur_change},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                                  item.currency_unit_label +
                                  ' (' +
                                  item.symbol +
                                  ')'
                                )}})],1)]}}],null,false,3000849733),model:{value:(_vm.editedItem.currency_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "currency_id", $$v)},expression:"editedItem.currency_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('datepicker',{key:_vm.cs,attrs:{"label":'Date Operation',"value":_vm.editedItem.opr_date,"edit":_vm.modify ||
                            (_vm.editedItem.statut_id == 1 &&
                              _vm.editedItem.active == 1 &&
                              _vm.$store.state.auth.includes('03003')),"clearable":false,"date_max":_vm.$store.state.today,"outlined":true,"rules":[
                            function (v) { return !!v || !_vm.tovalid || 'Date obligatoire'; } ]},model:{value:(_vm.editedItem.opr_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "opr_date", $$v)},expression:"editedItem.opr_date"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":_vm.modes,"item-text":"mode","item-value":"id","label":"Paiement","rules":[
                            function (v) { return !!v || !_vm.tovalid || 'Mode Paiement obligatoire'; } ],"dense":"","outlined":"","readonly":!_vm.modify &&
                            !(
                              _vm.editedItem.statut_id == 1 &&
                              _vm.editedItem.active == 1 &&
                              _vm.$store.state.auth.includes('03003')
                            )},model:{value:(_vm.editedItem.modepaiement),callback:function ($$v) {_vm.$set(_vm.editedItem, "modepaiement", $$v)},expression:"editedItem.modepaiement"}})],1)],1),(!_vm.dz)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","outlined":"","dense":"","label":"Taux de change","rules":[
                            function (v) { return !!v || v > 0 || 'Taux de change obligatoire'; } ],"readonly":!_vm.modify ||
                            _vm.editedItem.trans_id > 0 ||
                            _vm.editedItem.credit_id > 0 ||
                            _vm.editedItem.echeance_id > 0,"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()},"input":_vm.mont_change},model:{value:(_vm.editedItem.taux_change),callback:function ($$v) {_vm.$set(_vm.editedItem, "taux_change", _vm._n($$v))},expression:"editedItem.taux_change"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('currencyinput',{key:_vm.mk2,attrs:{"label":"Montant DA","readonly":"","dense":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.mont_dz),callback:function ($$v) {_vm.$set(_vm.editedItem, "mont_dz", $$v)},expression:"editedItem.mont_dz"}})],1)],1):_vm._e(),(_vm.trans_type == 'e' && _vm.editedItem.create_uid != 1)?_c('v-row',[_c('v-card',[_c('v-card-title',[_vm._v(" Execution ")]),_c('v-card-text',[_c('v-row',[(
                                _vm.$store.state.auth.includes('02003') ||
                                _vm.$store.state.isadmin
                              )?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.users_list,"item-text":"name","item-value":"id","label":"Agent","rules":[function (v) { return !!v || 'Agent obligatoire'; }],"dense":"","outlined":"","readonly":!_vm.modify ||
                                  _vm.editedItem.trans_id > 0 ||
                                  _vm.editedItem.credit_id > 0 ||
                                  _vm.editedItem.echeance_id > 0},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s("Solde " + (item.solde ? _vm.numberWithSpace( parseFloat(item.solde).toFixed( 2 ) ) : 0.0))+" ")])],1)]}}],null,false,295330692),model:{value:(_vm.editedItem.user_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "user_id", $$v)},expression:"editedItem.user_id"}})],1):_vm._e(),(
                                _vm.$store.state.auth.includes('02003') ||
                                _vm.$store.state.isadmin
                              )?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('datepicker',{key:_vm.cs,attrs:{"label":"Date Max","value":_vm.editedItem.date_limite,"edit":_vm.modify &&
                                  !_vm.editedItem.trans_id &&
                                  !_vm.editedItem.credit_id &&
                                  !_vm.editedItem.echeance_id,"date_min":_vm.$store.state.today,"clearable":true,"outlined":true,"dense":""},model:{value:(_vm.editedItem.date_limite),callback:function ($$v) {_vm.$set(_vm.editedItem, "date_limite", $$v)},expression:"editedItem.date_limite"}})],1):_vm._e()],1)],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","type":"text","label":"Comment","readonly":!_vm.modify ||
                            _vm.editedItem.trans_id > 0 ||
                            _vm.editedItem.credit_id > 0 ||
                            _vm.editedItem.echeance_id > 0,"dense":""},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1),_c('v-row',[(_vm.editedItem.id > 0 && _vm.trans_type == 'e')?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-select',{attrs:{"items":_vm.tier_contacts,"item-text":"nom","item-value":"id","label":"Autre Personne","dense":"","outlined":"","readonly":!_vm.modify &&
                            !(
                              _vm.editedItem.statut_id == 1 &&
                              _vm.$store.state.auth.includes('03003')
                            )},model:{value:(_vm.editedItem.contact_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "contact_id", $$v)},expression:"editedItem.contact_id"}})],1):_vm._e(),(
                          _vm.editedItem.currency_id == 114 &&
                          _vm.editedItem.id > 0 &&
                          _vm.trans_type == 'e'
                        )?_c('v-col',{attrs:{"cols":"12","sm":"9","md":"9"}},[_c('v-text-field',{attrs:{"outlined":"","type":"text","label":"Comment","readonly":!_vm.modify &&
                            !(
                              _vm.editedItem.statut_id == 1 &&
                              _vm.$store.state.auth.includes('03003')
                            ),"dense":""},model:{value:(_vm.editedItem.co_comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "co_comment", $$v)},expression:"editedItem.co_comment"}})],1):_vm._e()],1)],1)],1),_c('v-tab-item',{attrs:{"value":'5'}},[_c('listitems',{key:_vm.editedItem.id,attrs:{"title":'Frais',"headers":_vm.frais_headers,"list":_vm.frais,"showedit":true,"showstd":true,"qCreate":_vm.Qcreate_frais,"qUpdate":_vm.Qupdate_frais,"qDelete":_vm.Qdelete_frais,"Get_suf":'OprFrais',"add":_vm.modify,"del":_vm.modify,"Update":_vm.modify}})],1),_c('v-tab-item',{attrs:{"value":'8'}},[_c('filelist',{key:_vm.fdocl,attrs:{"item":_vm.editedItem,"isform":false,"editer":_vm.modify ||
                      (_vm.editedItem.statut_id == 1 &&
                        (_vm.$store.state.auth.includes('03003') ||
                          _vm.$store.state.isadmin)),"doc_type":5,"auth":_vm.auth},on:{"file_added":_vm.file_added,"file_deleted":_vm.file_deleted}})],1)],1)],1)],1)],1),_c('v-card-actions',[(
              _vm.editedItem.statut_id == 1 &&
              _vm.editedItem.id > 0 &&
              (_vm.$store.state.auth.includes('02003') || _vm.$store.state.isadmin) &&
              _vm.$store.state.me.id != _vm.editedItem.user_id
            )?_c('v-checkbox',{attrs:{"label":"E-mail Agent"},model:{value:(_vm.editedItem.email_agent),callback:function ($$v) {_vm.$set(_vm.editedItem, "email_agent", $$v)},expression:"editedItem.email_agent"}}):_vm._e(),(
              _vm.editedItem.statut_id == 1 &&
              _vm.editedItem.id > 0 &&
              (_vm.$store.state.auth.includes('02003') || _vm.$store.state.isadmin) &&
              _vm.trans_type == 'e'
            )?_c('v-checkbox',{attrs:{"label":"E-mail Operateur"},model:{value:(_vm.editedItem.email_opr),callback:function ($$v) {_vm.$set(_vm.editedItem, "email_opr", $$v)},expression:"editedItem.email_opr"}}):_vm._e(),_c('v-spacer'),(
              _vm.editedItem.statut_id == 2 &&
              _vm.editedItem.active == 1 &&
              (_vm.$store.state.auth.includes('05003') || _vm.$store.state.isadmin)
            )?_c('v-btn',{attrs:{"color":"red darken-1"},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(" Annuler ")],1):_vm._e(),(
              _vm.editedItem.statut_id == 1 &&
              _vm.editedItem.id > 0 &&
              (_vm.$store.state.auth.includes('02003') || _vm.$store.state.isadmin)
            )?_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.activating,"loading":_vm.activating},on:{"click":_vm.disable}},[_vm._v(" "+_vm._s(_vm.editedItem.active == 1 ? "Désactiver" : "Activer")+" ")]):_vm._e(),(
              _vm.editedItem.statut_id == 1 &&
              _vm.editedItem.active == 1 &&
              _vm.editedItem.id > 0 &&
              (_vm.$store.state.auth.includes('04003') || _vm.$store.state.isadmin)
            )?_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.validating,"loading":_vm.validating},on:{"click":_vm.validate}},[_vm._v(" Valider ")]):_vm._e(),(
              _vm.editedItem.statut_id == 1 &&
              (_vm.$store.state.auth.includes('02003') || _vm.$store.state.isadmin)
            )?_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.ch == 1 || _vm.saving,"loading":_vm.saving},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":_vm.close}},[_vm._v(" Fermer ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),[_c('confirmdialog',{ref:"confirm"})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }