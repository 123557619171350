<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="1200px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
            <v-spacer></v-spacer>
            <h2
              v-if="
                trans_type == 'e' &&
                editedItem.currency_id == 114 &&
                editedItem.sens
              "
            >
              {{ editedItem.sens == "1" ? "استلام" : "تسليم" }}
            </h2>
            <h2 v-if="trans_type == 'i' && editedItem.sens">
              {{ editedItem.sens == -1 ? "استلام" : "تسليم" }}
            </h2>
            <h2 v-if="trans_type == 'e' && editedItem.currency_id != 114">
              {{ editedItem.sens == 1 ? "Achat" : "Vente" }}
            </h2>
            <v-spacer></v-spacer>
            <v-chip :color="editedItem.status_color">
              {{ editedItem.status_name }}
            </v-chip>
          </v-card-title>
          <v-card-subtitle> {{ editedItem.tier_name }}</v-card-subtitle>
          <v-card-subtitle>
            <v-row
              v-if="
                trans_type == 'e' &&
                editedItem.currency_id == 114 &&
                editedItem.id > 0
              "
            >
              <v-col cols="12" sm="3" md="3">
                {{ "Opr. Org. : " + editedItem.org_no_opr }}
              </v-col>
              <v-col cols="12" sm="3" md="3">
                {{
                  "Montant Initial : " +
                  numberWithSpace(
                    editedItem.mont_init
                      ? parseFloat(editedItem.mont_init).toFixed(2)
                      : 0.0
                  )
                }}
              </v-col>
              <v-col cols="12" sm="3" md="3">
                {{
                  (editedItem.sens == 1 ? "Reçu : " : "Transfere : ") +
                  numberWithSpace(
                    editedItem.ttc_paye
                      ? parseFloat(editedItem.ttc_paye).toFixed(2)
                      : 0.0
                  )
                }}
              </v-col>
              <v-col cols="12" sm="3" md="3">
                {{
                  "Reste : " +
                  numberWithSpace(
                    editedItem.reste
                      ? parseFloat(editedItem.reste).toFixed(2)
                      : 0.0
                  )
                }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-tabs class="my-tabs" v-model="tab1" @change="tab_change">
                <v-tab href="#4" key="4">
                  <v-icon left> mdi-account-cash-outline </v-icon>
                  Transaction
                </v-tab>
                <v-tab href="#5" key="5" v-if="editedItem.id != -1">
                  Frais
                </v-tab>
                <v-spacer></v-spacer>
                <v-tab href="#8" key="8" v-if="editedItem.id != -1">
                  Documents attachés
                  <v-badge
                    bordered
                    :color="editedItem.nbdocs == 0 ? 'red' : 'blue'"
                    overlap
                  >
                    <template v-slot:badge>
                      <span> {{ editedItem.nbdocs }} </span>
                    </template>
                    <v-icon left> mdi-paperclip </v-icon>
                  </v-badge>
                </v-tab>
                <v-tabs-items v-model="currentTab">
                  <v-tab-item :value="'4'">
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-if="
                            !tier && editedItem.id == -1 && trans_type == 'e'
                          "
                        >
                          <v-autocomplete
                            v-model="editedItem.tier_id"
                            :items="tiers"
                            :item-text="'nom'"
                            :item-value="'id'"
                            label="Tier"
                            :rules="[(v) => !!v || 'Tier obligatoire']"
                            outlined
                            dense
                            @change="tier_change"
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.nom }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    item.tier_type_id == 1
                                      ? "Client"
                                      : item.tier_type_id == 2
                                      ? "Fournisseur"
                                      : "Autre"
                                  }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle>
                                  {{
                                    "Solde " +
                                    (item.solde
                                      ? numberWithSpace(
                                          parseFloat(item.solde).toFixed(2)
                                        )
                                      : 0.0)
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          v-if="trans_type == 'e' && editedItem.create_uid != 1"
                        >
                          <v-select
                            v-model.number="editedItem.sens"
                            :items="[
                              { id: 1, label: 'Achat' },
                              { id: -1, label: 'Vente' },
                            ]"
                            item-text="label"
                            item-value="id"
                            label="Operation"
                            :rules="[(v) => !!v || 'Operation obligatoire']"
                            dense
                            outlined
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                          >
                            <template v-slot:selection="{}">
                              <v-chip
                                :color="
                                  editedItem.sens == 1 ? 'green' : 'orange'
                                "
                                small
                              >
                                <span>{{
                                  editedItem.sens == 1 ? "Achat" : "Vente"
                                }}</span>
                              </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-chip
                                :color="item.id == 1 ? 'green' : 'orange'"
                                small
                              >
                                <span>{{
                                  item.id == 1 ? "Achat" : "Vente"
                                }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" v-if="trans_type == 'i'">
                          <v-select
                            v-model.number="editedItem.sens"
                            :items="[
                              { id: -1, label: 'Décaissser' },
                              { id: 1, label: 'Alimenter' },
                            ]"
                            item-text="label"
                            item-value="id"
                            label="Operation"
                            :rules="[(v) => !!v || 'Operation obligatoire']"
                            dense
                            outlined
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                          >
                            <template v-slot:selection="{}">
                              <v-chip
                                :color="
                                  editedItem.sens == 1 ? 'green' : 'orange'
                                "
                                small
                              >
                                <span>{{
                                  editedItem.sens == -1
                                    ? "Décaissser"
                                    : "Alimenter"
                                }}</span>
                              </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-chip
                                :color="item.id == 1 ? 'green' : 'orange'"
                                small
                              >
                                <span>{{
                                  item.id == -1 ? "Décaissser" : "Alimenter"
                                }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          v-if="
                            ($store.state.auth.includes('02003') ||
                              $store.state.isadmin) &&
                            trans_type == 'i'
                          "
                        >
                          <v-select
                            v-model="editedItem.user_id"
                            :items="users_list"
                            item-text="name"
                            item-value="id"
                            label="Caisse de "
                            :rules="[(v) => !!v || 'Caisse obligatoire']"
                            dense
                            outlined
                            @change="caisse_change"
                            :readonly="!modify"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                  {{
                                    "Solde " +
                                    (item.solde
                                      ? numberWithSpace(
                                          parseFloat(item.solde).toFixed(2)
                                        )
                                      : 0.0)
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <currencyinput
                            label="Montant"
                            v-model="editedItem.montant"
                            :rules="[
                              (v) => !!v || v > 0 || 'Montant obligatoire',
                            ]"
                            :readonly="
                              !modify &&
                              !(
                                editedItem.statut_id == 1 &&
                                editedItem.active == 1 &&
                                ($store.state.auth.includes('03003') ||
                                  $store.state.isadmin)
                              )
                            "
                            :key="mk"
                            @input="mont_change"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          v-if="editedItem.create_uid != 1"
                        >
                          <v-autocomplete
                            v-model="editedItem.currency_id"
                            :items="currencies"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Monnaie"
                            :filter="customFilter"
                            :rules="[(v) => !!v || 'Monnaie obligatoire']"
                            outlined
                            dense
                            @change="cur_change"
                            :readonly="
                              !modify ||
                              !(
                                $store.state.auth.includes('02003') ||
                                $store.state.isadmin
                              ) ||
                              caisse.owner == 0
                            "
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.name"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                  v-html="
                                    item.currency_unit_label +
                                    ' (' +
                                    item.symbol +
                                    ')'
                                  "
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <datepicker
                            :label="'Date Operation'"
                            v-model="editedItem.opr_date"
                            :value="editedItem.opr_date"
                            :edit="
                              modify ||
                              (editedItem.statut_id == 1 &&
                                editedItem.active == 1 &&
                                $store.state.auth.includes('03003'))
                            "
                            :clearable="false"
                            :date_max="$store.state.today"
                            :outlined="true"
                            :rules="[
                              (v) => !!v || !tovalid || 'Date obligatoire',
                            ]"
                            :key="cs"
                          ></datepicker>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-select
                            v-model="editedItem.modepaiement"
                            :items="modes"
                            item-text="mode"
                            item-value="id"
                            label="Paiement"
                            :rules="[
                              (v) =>
                                !!v || !tovalid || 'Mode Paiement obligatoire',
                            ]"
                            dense
                            outlined
                            :readonly="
                              !modify &&
                              !(
                                editedItem.statut_id == 1 &&
                                editedItem.active == 1 &&
                                $store.state.auth.includes('03003')
                              )
                            "
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row v-if="!dz">
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            autocomplete="off"
                            type="number"
                            outlined
                            dense
                            v-model.number="editedItem.taux_change"
                            label="Taux de change"
                            :rules="[
                              (v) =>
                                !!v || v > 0 || 'Taux de change obligatoire',
                            ]"
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                            @focus="$event.target.select()"
                            @input="mont_change"
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <currencyinput
                            label="Montant DA"
                            v-model="editedItem.mont_dz"
                            readonly
                            :key="mk2"
                            @focus="$event.target.select()"
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="trans_type == 'e' && editedItem.create_uid != 1"
                      >
                        <v-card>
                          <v-card-title> Execution </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                v-if="
                                  $store.state.auth.includes('02003') ||
                                  $store.state.isadmin
                                "
                              >
                                <v-select
                                  v-model="editedItem.user_id"
                                  :items="users_list"
                                  item-text="name"
                                  item-value="id"
                                  label="Agent"
                                  :rules="[(v) => !!v || 'Agent obligatoire']"
                                  dense
                                  outlined
                                  :readonly="
                                    !modify ||
                                    editedItem.trans_id > 0 ||
                                    editedItem.credit_id > 0 ||
                                    editedItem.echeance_id > 0
                                  "
                                >
                                  <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>

                                      <v-list-item-subtitle>
                                        {{
                                          "Solde " +
                                          (item.solde
                                            ? numberWithSpace(
                                                parseFloat(item.solde).toFixed(
                                                  2
                                                )
                                              )
                                            : 0.0)
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                v-if="
                                  $store.state.auth.includes('02003') ||
                                  $store.state.isadmin
                                "
                              >
                                <datepicker
                                  label="Date Max"
                                  v-model="editedItem.date_limite"
                                  :value="editedItem.date_limite"
                                  :edit="
                                    modify &&
                                    !editedItem.trans_id &&
                                    !editedItem.credit_id &&
                                    !editedItem.echeance_id
                                  "
                                  :date_min="$store.state.today"
                                  :clearable="true"
                                  :outlined="true"
                                  :key="cs"
                                  dense
                                ></datepicker>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            type="text"
                            label="Comment"
                            v-model="editedItem.comment"
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                            dense
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="3"
                          md="3"
                          v-if="editedItem.id > 0 && trans_type == 'e'"
                        >
                          <v-select
                            v-model="editedItem.contact_id"
                            :items="tier_contacts"
                            item-text="nom"
                            item-value="id"
                            label="Autre Personne"
                            dense
                            outlined
                            :readonly="
                              !modify &&
                              !(
                                editedItem.statut_id == 1 &&
                                $store.state.auth.includes('03003')
                              )
                            "
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="9"
                          md="9"
                          v-if="
                            editedItem.currency_id == 114 &&
                            editedItem.id > 0 &&
                            trans_type == 'e'
                          "
                        >
                          <v-text-field
                            outlined
                            type="text"
                            label="Comment"
                            v-model="editedItem.co_comment"
                            :readonly="
                              !modify &&
                              !(
                                editedItem.statut_id == 1 &&
                                $store.state.auth.includes('03003')
                              )
                            "
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item :value="'5'">
                    <listitems
                      :title="'Frais'"
                      :key="editedItem.id"
                      :headers="frais_headers"
                      :list="frais"
                      :showedit="true"
                      :showstd="true"
                      :qCreate="Qcreate_frais"
                      :qUpdate="Qupdate_frais"
                      :qDelete="Qdelete_frais"
                      :Get_suf="'OprFrais'"
                      :add="modify"
                      :del="modify"
                      :Update="modify"
                    ></listitems>
                  </v-tab-item>
                  <v-tab-item :value="'8'">
                    <filelist
                      :item="editedItem"
                      :isform="false"
                      :key="fdocl"
                      :editer="
                        modify ||
                        (editedItem.statut_id == 1 &&
                          ($store.state.auth.includes('03003') ||
                            $store.state.isadmin))
                      "
                      :doc_type="5"
                      :auth="auth"
                      @file_added="file_added"
                      @file_deleted="file_deleted"
                    >
                    </filelist>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-checkbox
              v-model="editedItem.email_agent"
              label="E-mail Agent"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('02003') || $store.state.isadmin) &&
                $store.state.me.id != editedItem.user_id
              "
            ></v-checkbox>
            <v-checkbox
              v-model="editedItem.email_opr"
              label="E-mail Operateur"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('02003') || $store.state.isadmin) &&
                trans_type == 'e'
              "
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              @click="cancel"
              v-if="
                editedItem.statut_id == 2 &&
                editedItem.active == 1 &&
                ($store.state.auth.includes('05003') || $store.state.isadmin)
              "
            >
              <v-icon>mdi-cancel</v-icon>
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="disable"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('02003') || $store.state.isadmin)
              "
              :disabled="activating"
              :loading="activating"
            >
              {{ editedItem.active == 1 ? "Désactiver" : "Activer" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="validate"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.active == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('04003') || $store.state.isadmin)
              "
              :disabled="validating"
              :loading="validating"
            >
              Valider
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="save"
              v-if="
                editedItem.statut_id == 1 &&
                ($store.state.auth.includes('02003') || $store.state.isadmin)
              "
              :disabled="ch == 1 || saving"
              :loading="saving"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
import CREATE_OPR from "../graphql/Trans/CREATE_OPR.gql";
import UPDATE_OPR from "../graphql/Trans/UPDATE_OPR.gql";

import CREATE_FRAIS from "../graphql/Trans/CREATE_FRAIS.gql";
import UPDATE_FRAIS from "../graphql/Trans/UPDATE_FRAIS.gql";
import DELETE_FRAIS from "../graphql/Trans/DELETE_FRAIS.gql";

export default {
  name: "transactions",
  components: {
    datepicker: () => import("./DatePicker.vue"),
    currencyinput: () => import("./CurrencyInput.vue"),
    filelist: () => import("../components/FileList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    listitems: () => import("../components/ListItems.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    tier: Object,
    list: Array,
    currencies: Array,
    modes: Array,
    tiers: Array,
    users: Array,
    contacts: Array,
    modify: Boolean,
    annuler: Boolean,
    trans_type: String,
  },
  watch: {
    editedItem: {
      handler() {
        this.ch++;
      },
      deep: true,
    },
  },
  data: () => ({
    tab1: 1,
    currentTab: 1,
    valid: true,
    villes: [],
    caisse: {},
    editedItem: {},
    frais_headers: [
      {
        text: "trans_id",
        value: "trans_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
      {
        text: "N°",
        value: "no",
        selected: true,
        index: 0,
      },
      {
        text: "Label",
        value: "label",
        type: "text",
        sm: "8",
        md: "8",
        rules: [(v) => !!v || "label obligatoire"],
        slot: "href",
        edit: true,
        selected: true,
      },
      {
        text: "Montant",
        value: "montant",
        selected: true,
        type: "number",
        align: "end",
        slot: "cur",
        sm: "4",
        md: "4",
        edit: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        type: "text",
        sm: "12",
        md: "12",
        edit: true,
      },
    ],
    defaultItem: {
      id: -1,
      statut_id: 1,
      active: 1,
      status_name: "Brouillon",
      status_color: "gray",
      email_agent: 1,
      email_opr: 1,
      nbdocs: 0,
    },
    saving: false,
    validating: false,
    activating: false,
    cs: 120,
    ch: 0,
    mk: 100,
    mk2: 200,
    fdocl: 1000,
    dz: false,
    tovalid: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    phoneNumber: "+213550910239", // The phone number in international format
    message: "Hello, I want to know more about your services.",
  }),

  computed: {
    tier_contacts() {
      let l = [];
      if (this.contacts)
        l = this.contacts.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      return l;
    },
    auth() {
      let a = this.istier == 0 ? "01001" : this.istier == 1 ? "01029" : "01030";
      return a;
    },
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouvelle Operation"
        : "Operation N° " + this.editedItem.no_opr;
    },
    users_list() {
      let l = [];
      if (this.users)
        this.users.forEach((element) => {
          if (element.autorisations) {
            if (element.autorisations.split(",").includes("03003"))
              l.push(element);
          } else if (element.profile.autorisations) {
            if (element.profile.autorisations.split(",").includes("03003"))
              l.push(element);
          }
        });

      return l;
    },
    frais() {
      let l = [];
      if (this.editedItem.frais) l = this.editedItem.frais;
      return l;
    },
    Qcreate_frais() {
      return CREATE_FRAIS;
    },
    Qupdate_frais() {
      return UPDATE_FRAIS;
    },
    Qdelete_frais() {
      return DELETE_FRAIS;
    },
  },

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.frais_headers[0].default = this.item.id;
        this.caisse_change();
        this.mk++;
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);

        if (this.tier) {
          this.editedItem.tier_id = this.tier.id;
          this.editedItem.tier_name = this.tier.nom;
          this.editedItem.tier_type_id = this.tier.tier_type_id;
        }
        this.editedItem.currency_id = this.$store.state.me.currency_id;
        if (this.trans_type == "e") {
          this.editedItem.sens = this.editedItem.tier_type_id == 1 ? 1 : -1;
        }
        if (this.users_list.length == 1)
          this.editedItem.user_id = this.users_list[0].id;
      }
      this.dz = this.editedItem.currency_id == "114";
      this.cs++;
    }
  },
  methods: {
    caisse_change() {
      let i = this.users.findIndex((elm) => elm.id == this.editedItem.user_id);
      if (i >= 0) {
        this.caisse = this.users[i];
        if (this.caisse.owner == 0) {
          this.editedItem.currency_id = "114";
          this.editedItem.taux_change = 1;
          this.editedItem.mont_dz = parseFloat(
            this.editedItem.montant * this.editedItem.taux_change
          ).toFixed(2);
          this.dz = true;
        }
      }
    },
    cur_change() {
      if (this.editedItem.currency_id == "114") {
        this.editedItem.taux_change = 1;
      }
      this.dz = this.editedItem.currency_id == "114";
      this.editedItem.mont_dz = parseFloat(
        this.editedItem.montant * this.editedItem.taux_change
      ).toFixed(2);
    },
    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
    tier_change() {
      let i = this.tiers.findIndex((elm) => elm.id == this.editedItem.tier_id);
      if (i >= 0) {
        this.editedItem.tier_name = this.tiers[i].nom;
        this.editedItem.tier_type_id = this.tiers[i].tier_type_id;
      }
    },
    tab_change() {
      this.currentTab = this.tab1;
    },

    mont_change() {
      if (this.editedItem.montant && this.editedItem.taux_change) {
        this.editedItem.mont_dz = parseFloat(
          this.editedItem.montant * this.editedItem.taux_change
        ).toFixed(2);
        this.mk2++;
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["name", "symbol", "currency_unit_label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    file_added(n) {
      this.editedItem.nbdocs = parseInt(this.editedItem.nbdocs) + n;
    },
    file_deleted(n) {
      this.editedItem.nbdocs = parseInt(this.editedItem.nbdocs) - n;
    },
    async disable() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          (this.editedItem.active == 1 ? "Désactiver" : "Activer") +
            " cette transaction?"
        )
      ) {
        this.activating = true;
        let v = {
          opr: {
            id: this.editedItem.id,
            active: this.editedItem.active == 1 ? 0 : 1,
            write_uid: this.$store.state.me.id,
          },
        };
        let r = await this.maj(UPDATE_OPR, v);
        if (r) {
          this.editedItem.active = this.editedItem.active == 1 ? 0 : 1;
          if (this.list) {
            this.$emit("refresh");
          } else this.$store.dispatch("Changed", true);
          this.$store.dispatch("alert", true);
        }
      }
    },
    async cancel() {
      if (this.$refs.form2.validate()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Annuler cette transaction?"
          )
        ) {
          let v = {
            opr: {
              id: this.editedItem.id,
              statut_id: 3,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(UPDATE_OPR, v);
          if (r) {
            this.editedItem.statut_id = 3;
            if (this.list) this.$emit("refresh");
            else this.$store.dispatch("Changed", true);
            this.$emit("validate");
            this.close();
          }
        }
        this.activating = false;
      }
    },
    async validate() {
      this.tovalid = true;
      if (this.$refs.form2.validate()) {
        let ok = true;
        let w = "";
        if (this.editedItem.modepaiement == 3 && this.editedItem.nbdocs == 0) {
          ok = false;
          this.snackbar_text =
            "Au moins un document doit être attaché a cette transaction";
          this.snackbar_color = "error";
          this.snackbar = true;
          this.tovalid = false;
        }
        if (
          (this.editedItem.sens == -1 && this.trans_type == "e") ||
          (this.editedItem.sens == 1 &&
            this.trans_type == "i" &&
            this.$store.state.me.id != this.editedItem.user_id)
        ) {
          let i = this.users.findIndex(
            (elm) => elm.id == this.$store.state.me.id
          );

          if (i >= 0) {
            if (this.users[i].solde < this.editedItem.mont_dz) {
              ok = false;
              this.snackbar_text =
                "Votre solde : " +
                this.numberWithSpace(this.users[i].solde.toFixed(2)) +
                " est insuffisant pour cette transaction";
              this.snackbar_color = "error";
              this.snackbar = true;
              this.tovalid = false;
            }
          }
        }

        if (this.editedItem.sens == 1 && this.editedItem.currency_id != "114") {
          let i = this.users_list.findIndex(
            (elm) => elm.id == this.editedItem.user_id
          );
          if (i >= 0) {
            if (this.users_list[i].solde < this.editedItem.mont_dz) {
              w =
                "Solde de votre agent : " +
                this.numberWithSpace(this.users_list[i].solde.toFixed(2)) +
                " insuffisant pour cette transaction";
            }
          }
        }
        if (ok)
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Valider cette transaction?",
              { color: "orange darken-3" },
              w
            )
          ) {
            this.validating = true;
            if (
              !(
                this.$store.state.auth.includes("02003") ||
                this.$store.state.isadmin
              )
            ) {
              this.editedItem.email_agent = true;
              this.editedItem.email_opr = true;
            }
            let v = {
              opr: {
                id: this.editedItem.id,
                statut_id: 2,
                contact_id: this.editedItem.contact_id,
                email_agent: this.editedItem.email_agent ? 1 : 0,
                email_opr: this.editedItem.email_opr ? 1 : 0,
                sens: this.editedItem.sens,
                opr_date: this.editedItem.opr_date,
                date_limite: this.editedItem.date_limite,
                opr_ref: this.editedItem.opr_ref,
                comment: this.editedItem.comment,
                co_comment: this.editedItem.co_comment,
                montant: parseFloat(this.editedItem.montant),
                currency_id: this.editedItem.currency_id,
                taux_change: this.editedItem.taux_change,
                modepaiement: this.editedItem.modepaiement,
                write_uid:
                  this.trans_type == "e"
                    ? this.$store.state.me.id
                    : this.editedItem.user_id,
              },
            };
            let r = await this.maj(UPDATE_OPR, v);
            if (r) {
              this.editedItem.mont_dz = parseFloat(this.editedItem.mont_dz);
              this.editedItem.operation = this.editedItem.sens == 1 ? "C" : "D";

              this.editedItem.color =
                this.editedItem.sens == 1 ? "green" : "orange";
              let i = this.modes.findIndex(
                (elm) => elm.id == this.editedItem.modepaiement
              );
              if (i >= 0) this.editedItem.type_paye = this.modes[i].mode;
              i = this.currencies.findIndex(
                (elm) => elm.id == this.editedItem.currency_id
              );
              if (i >= 0) this.editedItem.currency = this.currencies[i].name;
              this.editedItem.statut_id = 2;

              if (this.list) {
                this.$emit("refresh");
              } else this.$store.dispatch("Changed", true);
              this.$store.dispatch("alert", true);
              this.$emit("validate", this.editedItem);

              this.close();
            }
            this.validating = false;
          }
      }
    },
    async save() {
      if (this.$refs.form2.validate()) {
        this.saving = true;
        this.editedItem.mont_dz = parseFloat(this.editedItem.mont_dz);
        this.editedItem.operation = this.editedItem.sens == 1 ? "C" : "D";
        this.editedItem.color = this.editedItem.sens == 1 ? "green" : "orange";
        if (this.editedItem.user_id)
          this.editedItem.status_name = "Att. Compl.";
        if (!this.editedItem.user_id)
          this.editedItem.status_name = "Att. Valid";
        let i = this.modes.findIndex(
          (elm) => elm.id == this.editedItem.modepaiement
        );
        if (i >= 0) this.editedItem.type_paye = this.modes[i].mode;
        i = this.currencies.findIndex(
          (elm) => elm.id == this.editedItem.currency_id
        );
        if (i >= 0) this.editedItem.currency = this.currencies[i].name;
        i = this.users.findIndex((elm) => elm.id == this.editedItem.user_id);
        if (i >= 0) this.editedItem.user_name = this.users[i].name;
        this.editedItem.statut_id = 1;
        if (this.editedItem.id > -1) {
          let v = {
            opr: {
              id: this.editedItem.id,
              user_id: this.editedItem.user_id,
              contact_id: this.editedItem.contact_id,
              email_agent: this.editedItem.email_agent ? 1 : 0,
              email_opr: this.editedItem.email_opr ? 1 : 0,
              sens: this.editedItem.sens,
              opr_date: this.editedItem.opr_date,
              date_limite: this.editedItem.date_limite,
              opr_ref: this.editedItem.opr_ref,
              comment: this.editedItem.comment,
              co_comment: this.editedItem.co_comment,
              montant: parseFloat(this.editedItem.montant),
              currency_id: this.editedItem.currency_id,
              taux_change: this.editedItem.taux_change,
              modepaiement: this.editedItem.modepaiement,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(UPDATE_OPR, v);
          if (r) {
            if (this.list) this.$emit("refresh");
            else this.$store.dispatch("Changed", true);
            this.ch = 1;
          }
          this.saving = false;
        } else {
          let v = {
            opr: {
              tier_id: this.editedItem.tier_id,
              user_id: this.editedItem.user_id,
              contact_id: this.editedItem.contact_id,
              email_agent: this.editedItem.email_agent ? 1 : 0,
              email_opr: this.editedItem.email_opr ? 1 : 0,
              statut_id: this.editedItem.statut_id,
              sens: this.editedItem.sens,
              opr_date: this.editedItem.opr_date,
              date_limite: this.editedItem.date_limite,
              opr_ref: this.editedItem.opr_ref,
              comment: this.editedItem.comment,
              co_comment: this.editedItem.co_comment,
              montant: parseFloat(this.editedItem.montant),
              currency_id: this.editedItem.currency_id,
              taux_change: this.editedItem.taux_change,
              modepaiement: this.editedItem.modepaiement,
              create_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(CREATE_OPR, v);
          if (r) {
            this.editedItem.id = r.createOpr.id;
            this.frais_headers[0].default = this.editedItem.id;
            this.editedItem.no_opr = r.createOpr.no_opr;
            if (this.list) {
              this.$emit("refresh");
            } else this.$store.dispatch("Changed", true);
            this.$store.dispatch("alert", true);
            this.ch = 1;
          }
          this.saving = false;
        }
      }
    },
  },
};
</script>
